import {inject} from '@angular/core';
import {AuthService} from '../services/e2x/authorization.service';
import {ActivatedRoute, CanActivateFn, Router} from '@angular/router';

export const profileSetupGuard: CanActivateFn = (route, state) => {
    const router = inject(Router);
    const activatedRoute = inject(ActivatedRoute);
    const authService = inject(AuthService);

    const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));

    return !userInfo?.isAccountSetup ? true : router.navigate(['/flow-selection']);
};
