import {Injectable} from '@angular/core';

import {Router} from '@angular/router';
import {LoginService} from '../services';

@Injectable()
export class BusinessGuard  {

  constructor(private router: Router, private loginService: LoginService) {
  }

  canActivate() {
    if (this.loginService.isBusinessUser()) {
      if (!this.loginService.isAlive()) {
        this.router.navigate(['/users/logout']);
        return false;
      }
      if (this.loginService.passwordExpired()) {
        this.router.navigate(['/users/password-expired']);
        return false;
      }
      return this.checkUserStatus();
    }
    this.router.navigate(['/not-found']);
    return false;
  }

  checkUserStatus(): boolean {
    if (!this.loginService.hasCompletedProfile()) {
      this.router.navigate(['/users/profile']);
      return false;
    }
    if (!this.loginService.hasCompletedCompany()) {
      this.router.navigate(['/users/business']);
      return false;
    }
    if (!this.loginService.hasCompletedAccount()) {
      const businessList = this.loginService.getBusinessList();
      this.router.navigate(['/users/business/', businessList[0].business_id, 'accounts']);
      return false;
    }
    if (!this.loginService.isEmailVerified()) {
      this.router.navigate(['/users/unverified']);
      return false;
    }
    if (!this.loginService.hasApprovedAccount()) {
      this.router.navigate(['/users/unapproved-account']);
      return false;
    }
    this.loginService.setUserApproval();
    return true;
  }
}
