import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable()
export class MenuService {

  private _menuOpen: Subject<boolean> = new BehaviorSubject<boolean>(false);
  public isMenuOpen = this._menuOpen.asObservable();

  constructor() {
  }

  toggleMenu() {
    this._menuOpen.next(!this._menuOpen['value']);
  }

  closeMenu() {
    this._menuOpen.next(false);
  }

}
