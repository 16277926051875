import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {COIService} from 'src/app/shared/services/coi.service';
import {Account} from 'src/app/shared';
import * as moment from 'moment';
import {map} from 'rxjs/operators';

@Injectable()
export class EnergyGraphService extends COIService {
  getEnergyGraph(account_id, meter_id: number, resolutionUnit: string, resolution: number, data): Observable<any> {
    return this.httpPost(`/energy_graph/meter_energy/${account_id}/${meter_id}/${resolutionUnit}/${resolution}`, data);
  }

  exportCSVEnergyGraph(meter_id: number, data): Observable<any> {
    return this.http.post(`${this.apiUrl}/energy_graph/meter_energy/download_csv/${meter_id}`, data,
      {
        responseType: 'blob', headers: this.getBasicHeaders()
      })
      .pipe(map((res) => {
        return new Blob([res], { type: 'text/plain' })
      }));
  }

  getPerformanceGraph(event_id: number, data): Observable<any> {
    return this.httpPost(`/reports/event_performance_graph/${event_id}`, data);
  }

  getWeather(account: Account, start?: Date, end?: Date): Observable<any> {
    return this.httpGet(`/energy_graph/weather/${account.service_address.latitude}/${account.service_address.longitude}`).pipe(map(r => {
      const data = r;
      const weathers = [];
      data.daily.data.forEach(e => {
        const weather = {tempHigh: 0, tempLow: 0, date: null, summary: '', icon: ''};
        weather.tempHigh = e.temperatureHigh;
        weather.tempLow = e.temperatureMin;
        weather.date = new Date(e.time * 1000);
        weather.summary = e.summary;
        weather.icon = e.icon;
        weathers.push(weather);
      });
      return weathers;
    }));
  }
}
