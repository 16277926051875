import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {COIService} from '../coi.service';
import {User} from '../../models/users';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {HttpHeaders} from '@angular/common/http';

@Injectable()
export class RegisterService extends COIService {
  register(email: string, password: string): Observable<any> {
    const data = {email, password};
    const headers = this.getHeaders();
    return this.http
      .post(`${this.apiUrl}/users/register`, data, {
        headers: headers
      }).pipe(map(r => {
        const user = r as User;
        localStorage.setItem('isLoggedin', 'true');
        this.setUserInfo(user);
        return user;
      }), catchError(this.catchError));
  }

  createProfile(user: User): Observable<User> {
    return this.httpPost('/users/profile', user, r => {
      this.setUserInfo(r);
      return r;
    });
  }

  submitSurvey(survey: any): Observable<any> {
    return this.http.post(`${environment.lambdaApi}/coi_survey_send_email`, survey);
  }

  hubsportSubmit(survey: any, portalId: string, formGuid: string): Observable<any> {
    const data = JSON.stringify(survey);
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'});
    return this.http.post(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`, data, {headers});
  }

}
