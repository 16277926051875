import { tap } from 'rxjs/operators';
import {UserService} from '../users';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private apiUrl = environment.e2xApiUrl + '/auth';
    private isAuthenticatedSubject = new BehaviorSubject<boolean>(this.hasAccessToken());
    public credentialsSubject = new BehaviorSubject<{ email: string, password: string }>(null);

    constructor(private http: HttpClient, private router: Router, private userService: UserService) {}

    // Register a new user
    register(email: string, password: string): Observable<any> {
        this.credentialsSubject.next({email, password});
        return this.http.post(`${this.apiUrl}/register`, { email, password });
    }

    // Login user and store tokens
    login(email: string, password: string): Observable<any> {
        return this.http.post(`${this.apiUrl}/login`, { email, password }).pipe(
            tap((tokens: { accessToken: string; refreshToken: string }) => {
                this.storeTokens(tokens.accessToken, tokens.refreshToken);
                this.isAuthenticatedSubject.next(true);
            }),
        );
    }

    // Refresh the access token
    refreshTokens(): Observable<any> {
        const refreshToken = this.getRefreshToken();
        if (!refreshToken) {
            this.logout(); // If there's no refresh token, log the user out
            return new Observable();
        }

        return this.http.post(`${this.apiUrl}/refresh`, { refreshToken }).pipe(
            tap((tokens: { accessToken: string }) => {
                this.storeAccessToken(tokens.accessToken);
            }),
        );
    }

    // Logout user and clear tokens
    logout(): void {
        const refreshToken = this.getRefreshToken();
        this.http.post(`${this.apiUrl}/logout`, { id: this.userService.getUserInfo().user_id }).subscribe(() => {
            this.clearTokens();
            this.isAuthenticatedSubject.next(false);
            this.router.navigate(['/login']);
        }, error => {
            this.clearTokens();
            this.isAuthenticatedSubject.next(false);
            this.router.navigate(['/login']);
        });
    }

    // Check if user is authenticated
    isAuthenticated(): Observable<boolean> {
        return this.isAuthenticatedSubject.asObservable();
    }

    isLoggedIn(): boolean {
        return this.isAuthenticatedSubject.value;
    }

    // Private utility methods to manage tokens
    private storeTokens(accessToken: string, refreshToken: string): void {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
    }

    private storeAccessToken(accessToken: string): void {
        localStorage.setItem('accessToken', accessToken);
    }

    private clearTokens(): void {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
    }

     getAccessToken(): string | null {
        return localStorage.getItem('accessToken');
    }

    private getRefreshToken(): string | null {
        return localStorage.getItem('refreshToken');
    }

    private hasAccessToken(): boolean {
        return !!this.getAccessToken();
    }
}

