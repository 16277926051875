import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {COIService} from '../coi.service';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {AdminUtility} from '../../models';

@Injectable({
  providedIn: 'root'
})
export class E2xService extends COIService {
  protected apiUrl: string = environment.e2xApiUrl;

  public getWaitListStates() {
    return this.httpGet2(`${this.apiUrl}/wait-lists/states`).pipe(map((r: {
      states: {
        abbreviation: string,
        name: string
      }[]
    }) => {
      const data = r;
      return {data};
    }));
  }

  public saveToWaitList(data: {
    city: string,
    companyName: string,
    companyPointOfContactEmailAddress: string,
    companyPointOfContactName: string,
    companyPointOfContactTitle: string,
    state: { name: string, abbreviation: string }
    zipCode: string
  }) {
    return this.http.post(`${this.apiUrl}/wait-lists`, {
      city: data.city,
      companyPointOfContactEmail: data.companyPointOfContactEmailAddress,
      companyPointOfContactName: data.companyPointOfContactName,
      companyName: data.companyName,
      state: data.state.name,
      companyPointOfContactTitle: data.companyPointOfContactTitle
    }).pipe(map(r => {
      const res = r;
    }));
  }

  public getUtilityByUserId(userId: number): Observable<AdminUtility> {
    return this.httpGet2(`${this.apiUrl}/utilities/customers/${userId}`).pipe(map((r: AdminUtility) => {
      return r;
    }));
  }
}
