// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// @ts-ignore
// import * as data from './version.json';
import data from './version.json';

export const environment = {
  production: false,
  baseUrl: 'https://dev.coioptimizer.com/',
  apiUrl: 'https://dev.coioptimizer.com/webapi',
  e2xApiUrl: 'https://coi-e2x-v2-api-env-940908047455.us-east4.run.app/e2x',
  // e2xApiUrl: 'http://localhost:9229/e2x',
  notificationApiEndpoint: 'https://dev-notification.coioptimizer.com',
  demandManagementApiEndpoint: 'https://dev-demand-management-api.coioptimizer.com',
  carbonEmissionApiEndpoint: 'https://dev-carbon-emission-api.coioptimizer.com',
  carbonTrackingApiEndpoint: 'https://dev-carbon-tracking-api.coioptimizer.com',
  tecoLink : 'https://teco-dev.coioptimizer.com/',

  greenButtonApiEndpoint: 'https://dev-gbd-authorization-api.coioptimizer.com',
  conedRedirectionPRODEndpoint: 'https://coned.com/accounts-billing/dashboard/billing-and-usage/share-my-data-connections/third-party-authorization/redirect?client_id=0oa11ji1ziyo6N7Dj0h8',
  conedRedirectionQAEndpoint:'https://uat10.coned.com/en/accounts-billing/dashboard/billing-and-usage/share-my-data-connections/third-party-authorization/redirect?client_id=0oa11ji1ziyo6N7Dj0h8',
  conedTokenPRODApiEndpoint:'https://apit.coned.com/gbc/v1/oauth/v1/Token',
  conedTokenQAApiEndpoint:'https://qa.apit.coned.com/gbc/v1/oauth/v1/Token',
  gbdNotifyAPI: 'https://dev-gbd-data-sharing-api.coioptimizer.com',
  stripePublicKey: 'pk_test_51PbPLkIu0bfjEQzffhORzedc6CBYcfFg7ly2qMobIeVT9Mm2ELsfOY6YzOhnRVgcuqolBxJta5cE7RqLtG2LVeST00MSFNwWzf',

  demo: false,
  local: false,
  qa: false,
  dev: true,
  tenantID: {
    coi: 'tenant_coi',
    nypa: 'tenant_nypa',
    teco: 'tenant_teco'
  },
  tenantSubdomains: [
    'nypa',
    'teco'
  ],
  version: data.version,
  lambdaApi: 'https://je7p6k5wf1.execute-api.us-east-1.amazonaws.com/dev',
  paypal: 'AeKm0dh9QCwloOJ1Ck-BGee1JjLM5VvPPZz_X1vwVwSQ8Ynoe6piA6ocKuIexkyami7IXzeYdEnS9UJs'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
