import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {COIService} from '../services';
import {environment} from '../../../environments/environment';

@Injectable()
export class TenantGuard  {
  constructor(private coiService: COIService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const domain = window.location.hostname;
    if (domain.indexOf('.') >= 0) {
      const subdomain = domain.split('.')[0].split('-')[0];
      if (environment.tenantSubdomains.includes(subdomain)) {
        this.coiService.setTenantID(environment.tenantID[subdomain]);
        return true;
      }
    }
    this.coiService.setTenantID(environment.tenantID.coi);
    return true;
  }
}
