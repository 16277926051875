import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {COIService} from '../coi.service';
import {Program} from '../../models/business';
import {Dashboard, Event, EventAccount} from '../../models/utility';
import {catchError, map} from 'rxjs/operators';
import {Account, User} from '../../models/users';

@Injectable()
export class EventService extends COIService {
  readProgramList(utility_id: number, queryParams): Observable<{ message: string, programList: Program[] }> {
    return this.httpGetNew(`/event/${utility_id}/program_list`, queryParams);
  }

  readEnrolledAccount(utility_id: number, skip: number, page_size: number, programList): Observable<{ message: string, accountList: Account[] }> {
    return this.httpPost(`/event/${utility_id}/enrolled_account_list/${skip}/${page_size}`, programList);
  }

  schedule(eventList: Event[], utility_id: number): Observable<{ message: string }> {
    return this.httpPost(`/event/${utility_id}`, {eventList});
  }

  readEventList(utility_id: number, queryParams: any): Observable<{ message: string, eventList: Event[] }> {
    return this.httpGetNew(`/event/${utility_id}/list`, queryParams);
  }

  eventsDownloadCSV(utility_id: number, event_id: number, timezone: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/event/${utility_id}/${event_id}/list/${timezone}/download_csv`, '',
      {
        responseType: 'blob', headers: this.getBasicHeaders()
      }).pipe(map((res) => {
      return new Blob([res], {type: 'text/plain'});
    }));
  }

  readUtilityUser(utility_id: number, queryParams: any): Observable<{ message: string, list: User[] }> {
    return this.httpGetNew(`/users/list/utility/${utility_id}`, queryParams);
  }

  readEndedEventListByAccount(account_id: number, skip?: number, page_size?: number): Observable<{ message: string, eventList: Event[] }> {
    return this.httpGetNew(`/event/account/${account_id}/ended_list`, {skip, page_size});
  }

  readEndedEventListByUtility(utility_id: number, queryParams: any): Observable<{ message: string, eventList: Event[] }> {
    return this.httpGetNew(`/event/${utility_id}/ended_list`, queryParams);
  }

  readFutureEventList(utility_id: number, queryParams: any): Observable<{ message: string, eventList: Event[], totalRecords: number }> {
    return this.httpGetNew(`/event/${utility_id}/future_list`, queryParams);
  }

  readFutureListByAccount(account_id: number, queryParams: any): Observable<{ message: string, eventList: Event[], totalRecords: number }> {
    return this.httpGetNew(`/event/account/${account_id}/future_list`, queryParams);
  }

  readPastListByAccount(account_id: number, queryParams: any): Observable<{ message: string, eventList: Event[], totalRecords: number }> {
    return this.httpGetNew(`/event/account/${account_id}/past_list`, queryParams);
  }

  readEventAccountList(utility_id: number, eventList: Event[]): Observable<{ message: string, eventAccountList: EventAccount[] }> {
    return this.httpGet(`/event/${utility_id}/event_account_list/${eventList[0].event_id}`);
  }

  cancel(event: Event): Observable<{ message: string }> {
    return this.httpPut(`/event/${event.program.utility_id}/cancel/${event.event_id}`, '');
  }

  end(event: Event): Observable<Event> {
    return this.httpPut(`/event/${event.program.utility_id}/end/${event.event_id}`, '');
  }

  //ToDo: this endpoint should be changed from event service to some generic dashboard service.
  getUtilityDashboardData(utility_id: number): Observable<Dashboard> {
    return this.httpGetNew(`/utility/dashboard/${utility_id}`).pipe(map(response => {
      const dashboard: Dashboard = {
        admin: {
          pendingAccount: response.pendingAccount,
          pendingEnrollment: response.pendingEnrollment
        },
        optimizeDR: {
          registeredCapacity: response.registeredCapacityDR,
          events: {
            futureEvents: response.futureEvents,
            pastEvents: response.pastEvents
          }
        },
        optimizeEE: {
          registeredCapacity: response.registeredCapacityEE
        }
      } as Dashboard;
      return dashboard;
    }), catchError(this.catchError));
  }

  getEventDetailsPerformanceGraph(account_id: number,utility_id: number, meter_id: number, event_id: number, data): Observable<any> {
    return this.httpPost(`/reports/meter_event_performance_graph/${account_id}/${meter_id}/event/${event_id}`, data);
  }

  getEventDetails(utility_id: number, account_id: number, event_id: number): Observable<any> {
    return this.httpGet(`/reports/account_event_performance/${account_id}/${event_id}`);
  }

  getEventDetailsNew(utility_id: number, account_id: number, event_id: number): Observable<any> {
    return this.httpPost(`/reports/account_event_performance/${account_id}/${event_id}`);
  }

  getBusinessEventDetails(account_id: number, event_id: number, data): Observable<any> {
    return this.httpPost(`/reports/event_performance_graph/${account_id}/${event_id}`, data);
  }
}
