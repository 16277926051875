import {Injectable} from '@angular/core';
import {COIService} from 'src/app/shared/services/coi.service';

@Injectable()
export class BusinessHeaderService extends COIService {
  getUserName() {
    return (this.getUserInfo().first_name || '') + ' ' + (this.getUserInfo().last_name || '');
  }

  getLogoImg(): string {
    const tenantId = this.getTenantID();
    switch (tenantId) {
      case this.getTenantIDList().coi:
        return 'logo.png';
      case this.getTenantIDList().teco:
        return 'tenant_teco_logo.jpg';
      default:
        return 'logo.png';
    }
  }
}
