import {Injectable} from '@angular/core';

enum Status {
  INITIALIZED = 0,
  APPROVED = 1,
  PENDING = 2,
  DECLINED = 4,
  OTHER = 8
};

enum ProgramType {
  DR = 1,
  EE = 2,
  RE = 3
};

@Injectable()
export class ConfigsService {
  pageSize = 20;
  status = Status;
  programType = ProgramType;

  constructor() {
  }

}
