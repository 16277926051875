import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {COIService} from '../coi.service';
import {Program, ProgramType, RateProgram} from '../../models/business';
import {ProgramSubType} from '../../models/business/program-subtype';


@Injectable()
export class ProgramService extends COIService {
  getPrograms(utility_id: number, queryParams): Observable<{ programList: Program[], message: string, totalRecords: number }> {
    return this.httpGetNew(`/program/list/${utility_id}`, queryParams);
  }

  getProgramById(program_id: number): Observable<{ program: Program, message: string }> {
    return this.httpGetNew(`/program/${program_id}`);
  }

  readProgramTypeList(utility_id: number): Observable<{ message: string, programTypeList: ProgramType[] }> {
    return this.httpGetNew(`/program/program_type_list/${utility_id}`);
  }

  readProgramSubTypeList(utility_id: number, program_type_id: number): Observable<{ message: string, programSubTypeList: ProgramSubType[] }> {
    return this.httpGetNew(`/program/utility/${utility_id}/program-type/${program_type_id}/program-subtype/list`);
  }

  create(program: Program, utility_id: number): Observable<any> {
    return this.httpPost(`/program/${utility_id}`, program);
  }

  update(program_id: number, program: Program): Observable<any> {
    return this.httpPut(`/program/${program_id}`, program);
  }

  updateRateProgram(program_id: number, rateProgram: RateProgram): Observable<RateProgram> {
    return this.httpPost(`/program/${program_id}/rate`, rateProgram);
  }

  delete(program_id: number): Observable<any> {
    return this.httpDelete(`/program/${program_id}`);
  }
}
