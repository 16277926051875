import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {COIService} from 'src/app/shared/services/coi.service';
import {Account, Enrollment} from 'src/app/shared/models';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class EnrollmentService extends COIService {
  readEnrollmentList(utility_id: number, queryParams?: any): Observable<{ message: string, totalRecords: number, enrollmentList: Enrollment[] }> {
    return this.httpGetNew(`/enrollment/list/utility/${utility_id}`, queryParams);
  }

  readVerifiedAccountList(business_id: number): Observable<{ message: string, accountList: Account[] }> {
    return this.httpGet(`/enrollment/business/${business_id}`);
  }

  create(enrollment): Observable<{ enrollment: Enrollment }> {
    return this.httpPost(`/enrollment`, enrollment);
  }

  updateAssetList(enrollment_id, enrollment): Observable<{ message: string }> {
    return this.httpPost(`/enrollment/update-asset-list/${enrollment_id}`, enrollment);
  }

  registeCapacityUpdate(enrollment) {
    return this.httpPost(`/enrollment/${enrollment.enrollment_id}/registered-capacity`, enrollment);
  }

  getEnrollment(enrollment_id): Observable<{ enrollment: Enrollment }> {
    return this.httpGetNew(`/enrollment/${enrollment_id}`);
  }

  readAccountEnrollmentSummary(account_id: number): Observable<{ message: string, totalRecords: number, enrollmentList: Enrollment[] }> {
    return this.httpGet(`/enrollment/summary/account/${account_id}`);
  }

  readBusinessEnrollmentSummary(business_id: number, skip: number, page_size: number): Observable<{ message: string, enrollmentList: Enrollment[], totalRecords: number }> {
    const enrollmentList: Enrollment[] = [];
    let totalRecords: number;
    return this.httpGetNew(`/account/list/${business_id}`, {skip, page_size}).pipe(map(response => {
      totalRecords = response.totalRecords || 0;
      if (response.accountList.length) {
        response.accountList.forEach(account => {
          if (account.enrollmentList && account.enrollmentList.enrollmentList.length) {
            account.enrollmentList.enrollmentList.forEach(enrollment => {
              enrollment.account = account;
              enrollmentList.push(enrollment);
            });
          }
        });
      }
      return {message: '', enrollmentList, totalRecords};
    }), catchError(this.catchError));
  }

  exportCSV(business_id: number): Observable<any> {
    return this.http.post(`${this.apiUrl}/enrollment/list/business/${business_id}/download_csv`, {},
      {
        responseType: 'blob', headers: this.getBasicHeaders()
      })
      .pipe(map((res) => {
        return new Blob([res], {type: 'text/plain'});
      }), catchError(this.catchError));
  }

  exportEnrollmentApprovalCSV(utility_id: number, queryParams: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/enrollment/list/utility/${utility_id}/download_csv`, {},
      {
        params: queryParams,
        responseType: 'blob', headers: this.getBasicHeaders()
      })
      .pipe(map((res) => {
        return new Blob([res], {type: 'text/plain'});
      }), catchError(this.catchError));
  }
}
