import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {COIService} from 'src/app/shared/services/coi.service';
import {InstallationAssessment} from 'src/app/shared';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class COIAdminService extends COIService {
  importData(list: any[], utility_id: number): Observable<any> {
    return this.httpPost(`/coi_admin/import_customer/${utility_id}`, {list: list});
  }

  readInstallationAssessment(utility_id: number, queryParams: any): Observable<{ totalRecords: number, list: InstallationAssessment[] }> {
    return this.httpGetNew(`/coi_admin/installation_assessment/${utility_id}`, queryParams);
  }

  getAssessmentImage(objectKey: string) {
    return this.http
      .get(`${this.apiUrl}/coi_admin/installation_assessment/images/site-assessment-installation/${objectKey}`, {
        headers: this.getBasicHeaders().append('Content-Type', 'image/jpeg'),
        responseType: 'blob'
      }).pipe(map((res: Blob) => {
        return new Blob([res], {type: 'image/jpeg'});
      }), catchError(this.catchError));
  }
}
