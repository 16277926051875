import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {COIMessage} from '../models/coi-message';

@Injectable()
export class MessagesService {
    public messageType = {
        warning: 'warn',
        success: 'success',
        info: 'info',
        error: 'error'
    };
    private messageTimeOut = 5000;
    private _message: Subject<COIMessage> = new BehaviorSubject<COIMessage>({});
    public message = this._message.asObservable();

    constructor() {
    }

    show(message: string, type: string = this.messageType.info, timeout: number = this.messageTimeOut) {
        const coiMessage: COIMessage = {
            message: message,
            type: type
        } as COIMessage;
        this._message.next(coiMessage);
        setTimeout(() => {
            this._message.next({});
        }, timeout);
    }
}
