import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { LoginService } from '../services';

@Injectable()
export class UtilityGuard  {

    constructor(private router: Router, private loginService: LoginService) { }

    canActivate() {
        if (this.loginService.isUtilityUser()) {
            if (!this.loginService.isAlive()) {
                this.router.navigate(['/users/logout']);
                return false;
            }
            if (this.loginService.passwordExpired()) {
                this.router.navigate(['/users/password-expired']);
                return false;
            }
            return true;
        }

        this.router.navigate(['/not-found']);
        return false;
    }
}
