import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {COIService} from 'src/app/shared/services/coi.service';
import {DashboardBusiness} from 'src/app/shared/models/business';
import {map} from 'rxjs/operators';

@Injectable()
export class BusinessDashboardService extends COIService {

    getBusinessDashboardData(business_id: number): Observable<DashboardBusiness> {
        return this.httpGetNew(`/business/dashboard/business/${business_id}`).pipe(map(response => {
            const dashboard: DashboardBusiness = {
                optimizeDR: {
                    events: {
                        pastEvents: response.pastEvents,
                        futureEvents: response.futureEvents
                    },
                    credits: response.creditsDR,
                    revenue: response.revenueDR
                },
                optimizeEE: {
                    credits: response.creditsEE
                }
            } as DashboardBusiness;
            return dashboard;
        }));
    }
}
