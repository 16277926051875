import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {MenuItem} from 'primeng/api';
import {Event, NavigationStart, Router} from '@angular/router';

@Injectable()
export class BreadcrumbService {

  private _items: Subject<MenuItem[]> = new BehaviorSubject<MenuItem[]>([]);

  public items = this._items.asObservable();

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.setBreadcrumbItems([]);
      }
    });
  }

  setBreadcrumbItems(items: MenuItem[]) {
    this._items.next(items);
  }
}
