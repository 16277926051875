import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { LoginService } from '../services';

@Injectable()
export class AdminGuard  {

    constructor(private router: Router, private loginService: LoginService) { }

    canActivate() {
        if (this.loginService.isCoiUser()) {
            return true;
        }

        this.router.navigate(['/not-found']);
        return false;
    }
}
