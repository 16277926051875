import {Injectable} from '@angular/core';
import {
    Account,
    AccountRole,
    Business,
    BusinessRole,
    Role,
    User,
    Utility,
    UtilityRole
} from './../../shared';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class COIService {
    protected apiUrl: string = environment.apiUrl;
    protected tenantID = environment.tenantID;

    constructor(protected http: HttpClient, private title: Title) {
    }

    defaultMap(r) {
        try {
            return r.json();
        } catch (e) {
            return r;
        }
    }

    isLogin() {
        return !!localStorage.getItem('isLoggedin');
    }

    isCoiUser() {
        return this.hasRole('coi_user');
    }

    isBusinessUser() {
        return this.hasRole('business_user');
    }

    isUtilityUser() {
        return this.hasRole('utility_user');
    }

    applicationModule() {
        if (this.isUtilityUser()) {
            return 'utility';
        }
        if (this.isBusinessUser()) {
            return 'business';
        }
        return '';
    }

    hasRole(role_name: string): boolean {
        const userRoleList = this.getRoleList();
        const index = userRoleList.findIndex(item => {
            return item.role.role_name === role_name;
        });
        return index !== -1;
    }

    getBusinessList(): Business[] {
        try {
            const businessList = JSON.parse(localStorage.getItem('businessList'));
            return businessList || [];
        } catch (error) {
            return [];
        }
    }

    getUtilityList(): Utility[] {
        try {
            const list = JSON.parse(localStorage.getItem('utilityList'));
            return list || [];
        } catch (error) {
            return [];
        }
    }

    getAccountList(): Account[] {
        try {
            const list = JSON.parse(localStorage.getItem('accountList'));
            return list || [];
        } catch (error) {
            return [];
        }
    }

    getIpAddress(): string {
        return localStorage.getItem('ip');
    }

    setIpAddress() {
        this.http.get('https://api.ipify.org/?format=json').subscribe((response: any) => {
            localStorage.setItem('ip', response.ip);
        });
    }

    getAccountById(account_id) {
        return this.getAccountList().find(account => {
            return account.account_id === parseInt(account_id);
        });
    }

    getRoleList(): { user_id: number, user_role_id: number, role: Role }[] {
        try {
            const roleList = JSON.parse(localStorage.getItem('role'));
            return roleList || [];
        } catch (error) {
            return [];
        }
    }

    setRoleList(data) {
        localStorage.setItem('role', JSON.stringify(data.userRoleList));
    }

    setBusinessList(data) {
        const list = data.userBusinessRoleList as BusinessRole[];
        const index = [] as number[];
        const temp = list.map(r => r.business).filter(r => {
            if (index.findIndex(i => i === r.business_id) === -1) {
                index.push(r.business_id);
                return true;
            }
            return false;
        });
        localStorage.setItem('businessList', JSON.stringify(temp));
    }

    setUtilityList(data) {
        const list = data.userUtilityRoleList as UtilityRole[];
        const index = [] as number[];
        const temp = list.map(r => r.utility).filter(r => {
            if (index.findIndex(i => i === r.utility_id) === -1) {
                index.push(r.utility_id);
                return true;
            }
            return false;
        });
        localStorage.setItem('utilityList', JSON.stringify(temp));
    }

    setAccountList(data) {
        const list = data.userAccountRoleList as AccountRole[];
        const index = [] as number[];
        const temp = list.map(r => r.account).filter(r => {
            if (index.findIndex(i => i === r.account_id) === -1) {
                index.push(r.account_id);
                return true;
            }
            return false;
        });
        localStorage.setItem('accountList', JSON.stringify(temp));
    }

    getUserInfo(): User {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        return userInfo;
    }

    getProgramType(programTypeId: number) {
        return (programTypeId === 1) ? 'Demand Response' : 'Energy Efficiency';
    }

    setUserInfo(data) {
        this.setToken(data.token);
        this.setRoleList(data);
        this.setBusinessList(data);
        this.setUtilityList(data);
        this.setAccountList(data);
        this.keepalive();
        const user = {} as User;
        user.user_id = data.user_id;
        user.email = data.email;
        user.user_name = data.user_name;
        user.first_name = data.first_name;
        user.last_name = data.last_name;
        user.primary_phone = data.primary_phone;
        user.secondary_phone = data.secondary_phone;
        user.password_create_time = data.password_create_time;
        user.user_setting = data.user_setting;
        user.verified_flag = data.verified_flag;
        user.permissions = data.permissions;
        localStorage.setItem('userInfo', JSON.stringify(user));
    }

    setTenantID(tenantID: string) {
        localStorage.setItem('tenantID', tenantID);
    }

    getTenantID(): string {
        return localStorage.getItem('tenantID');
    }

    isCoiTenant(): boolean {
        return this.getTenantID() === environment.tenantID.coi;
    }

    isTecoTenant(): boolean {
        return this.getTenantID() === environment.tenantID.teco;
    }

    getTenantIDList() {
        return this.tenantID;
    }

    getHeaderLogo(): string {
        return 'logo-optimize-marketplace.svg';
    }

    getBigLogo(): string {
        return 'logo-tagline.svg';
    }

    getBackgroundImage(): string {
        return 'tenant_coi_background.jpg';
    }

    getUserName() {
        return this.getUserInfo().user_name || (this.getUserInfo().first_name || '') + ' ' + (this.getUserInfo().last_name || '');
    }

    setPageTitle(title) {
        this.title.setTitle('COI Optimizer - ' + title);
    }

    keepalive() {
        localStorage.setItem('lastAliveTime', new Date().toString());
    }

    setUserApproval() {
        localStorage.setItem('approvedUser', 'true');
    }

    isApprovedUser() {
        return !!localStorage.getItem('approvedUser');
    }

    areEqualAddress(addressA, addressB): boolean {
        if (!addressA.latitude && !addressB.latitude) {
            return addressA.street === addressB.street;
        }
        if (addressA.latitude && addressB.latitude) {
            return addressA.latitude === addressB.latitude && addressA.longitude === addressB.longitude;
        }
        return false;
    }

    protected getHeaders() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        });
        headers = this.setDefaultHeaders(headers);
        return headers;
    }

    protected getBasicHeaders() {
        let headers = new HttpHeaders();
        headers = this.setDefaultHeaders(headers);
        return headers;
    }

    protected getUploadFileHeaders() {
        let headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers = this.setDefaultHeaders(headers);
        return headers;
    }

    protected getUploadImageHeaders() {
        // let headers = new HttpHeaders({'Content-Type': 'multipart/form-data'});
        let headers = new HttpHeaders({'Accept': 'application/json'});
        headers = this.setDefaultHeaders(headers);
        return headers;
    }

    protected clearStorage() {
        const getTenantID = this.getTenantID();
        localStorage.clear();
        this.setTenantID(getTenantID);
    }

    protected getToken() {
        return JSON.parse(localStorage.getItem('token'));
    }

    protected setToken(token) {
        localStorage.setItem('token', JSON.stringify(token));
    }

    protected catchError(err: HttpErrorResponse, caught: Observable<any>): Observable<{}> {
        switch (err.status) {
            case (0):
                throw new Error('Can not connect to server.');
            case (404):
                throw new Error('Page not found.');
            case (401):
                setTimeout(() => {
                    const tenantID = localStorage.getItem('tenantID');
                    localStorage.clear();
                    localStorage.setItem('tenantID', tenantID);
                    window.location.reload();
                }, 2000);
        }
        let r;
        try {
            r = err.error.message || err.message;
        } catch (error) {
            throw err;
        }
        throw r;
    }

    protected httpGet(url: string, mapCallback: any = this.defaultMap) {
        /*return this.http
          .get(`${this.apiUrl}${url}`, {
            headers: this.getHeaders()
          }).pipe(map(map), catchError(this.catchError));*/
        return this.httpPost(url, '', mapCallback);
    }

    protected httpGet2(url: string, mapCallback: any = this.defaultMap) {
        return this.http
            .get(url, {
                headers: this.getHeaders()
            });
        // return this.httpPost(url, '', mapCallback);
    }

    /* TODO: REMOVE THIS METHOD WHEN THE API SUPPORT GET ENDPOINTS PROPERLY */
    protected httpGetNew(url: string, queryParams?, mapCallback: any = this.defaultMap) {
        const options = {
            headers: this.getHeaders()
        };
        if (queryParams) {
            options['params'] = queryParams;
        }
        return this.http
            .get(`${this.apiUrl}${url}`, options).pipe(map(mapCallback), catchError(this.catchError));
    }

    protected httpPost(url: string, data: any = '', mapCallback: any = this.defaultMap) {
        return this.http
            .post(`${this.apiUrl}${url}`, JSON.stringify(data), {
                headers: this.getHeaders()
            }).pipe(map(mapCallback), catchError(this.catchError));
    }

    protected httpPost2(url: string, data: any = '', mapCallback: any = this.defaultMap) {
        return this.http
            .post(url, JSON.stringify(data), {
                headers: this.getHeaders()
            });
    }

    protected httpPostWithQueryParams(url: string, data: any = '', params: any, mapCallback: any = this.defaultMap) {
        return this.http
            .post(`${this.apiUrl}${url}`, JSON.stringify(data), {
                headers: this.getHeaders(),
                params
            }).pipe(map(mapCallback), catchError(this.catchError));
    }

    protected httpPut(url: string, data: any = '', mapCallback: any = this.defaultMap) {
        return this.http
            .put(`${this.apiUrl}${url}`, JSON.stringify(data), {
                headers: this.getHeaders()
            }).pipe(map(mapCallback), catchError(this.catchError));
    }

    protected httpE2XPut(url: string, data: any = '', mapCallback: any = this.defaultMap) {
        return this.http.put(url, JSON.stringify(data), {
            headers: this.getHeaders()
        }).pipe(map(mapCallback), catchError(this.catchError));
    }

    protected httpPatch(url: string, data: any = '', mapCallback: any = this.defaultMap) {
        return this.http
            .patch(`${this.apiUrl}${url}`, JSON.stringify(data), {
                headers: this.getHeaders()
            }).pipe(map(mapCallback), catchError(this.catchError));
    }

    protected httpDelete(url: string, mapCallback: any = this.defaultMap) {
        return this.http
            .delete(`${this.apiUrl}${url}`, {
                headers: this.getHeaders()
            }).pipe(map(mapCallback), catchError(this.catchError));
    }

    private setDefaultHeaders(headers) {
        const token = this.getToken();
        if (token) {
            headers = headers.append('Authorization', 'Bearer ' + token);
        }
        const tenantId = this.getTenantID();
        if (tenantId) {
            headers = headers.append('X-TenantID', tenantId);
        }
        return headers;
    }
}


