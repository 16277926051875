import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {COIService} from 'src/app/shared/services/coi.service';
import {Event, EventAccount} from 'src/app/shared';
import {EventReportPerformance} from '../../index';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class DemandResponseService extends COIService {
  readEventList(business_id: number, queryParams: any): Observable<{ message: string, eventList: Event[], totalRecords: number }> {
    return this.httpGetNew(`/demand-response/${business_id}/event/acknowledgement/list`, queryParams);
  }

  acknowledge(eventAccountId: number): Observable<EventAccount> {
    return this.httpPut(`/demand-response/acknowledge/${eventAccountId}`, '');
  }

  decline(eventAccountId: number): Observable<EventAccount> {
    return this.httpPut(`/demand-response/decline/${eventAccountId}`, '');
  }

  getPerformanceList(business_id: number, queryParams: any, filterParams?: any): Observable<{ message: string, list: EventReportPerformance[], totalRecords: number }> {
    return this.httpPostWithQueryParams(`/reports/business/${business_id}/event_performance`, filterParams, queryParams);
  }

  performanceDownloadCSV(business_id: number, filterParams: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/reports/business/${business_id}/event_performance/download_csv`, filterParams,
      {
        responseType: 'blob', headers: this.getBasicHeaders()
      })
      .pipe(map((res) => {
        return new Blob([res], {type: 'text/plain'});
      }), catchError(this.catchError));
  }
}
