import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {COIService} from '../coi.service';

@Injectable()
export class UtilitySettingService extends COIService {
  getLogoImg(): Observable<{ logo_img: string }> {
    return this.httpPost(`/setting/tenantId/${this.getTenantID()}`);
  }
}
