import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import {COIService} from '../coi.service';

@Injectable()
export class MeterService extends COIService {
  create(meter: any): Observable<{ message: string, list: any[], totalRecords: number }> {
    return this.httpPost(`/meter`, meter);
  }

  updateSummary(startDate: string, meterNumber: string): Observable<{ message: string, list: any[], totalRecords: number }> {
    return this.httpPost(`/meter/manually_update_summary/${startDate}/${meterNumber}`);
  }

  
  readListForUtility(utility_id: number, queryParams: any, filterParams?: any): Observable<{ message: string, meterList:  any[], totalRecords: number }> {
    return this.httpPostWithQueryParams(`/meter/list/utility/${utility_id}`, filterParams, queryParams);
  }

  customersWithContactsReportDownloadCSV(filterParams: any, queryParams: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/meter/list/utility/customers_contacts/download_csv`, filterParams,
      {
        params: queryParams,
        responseType: 'blob', headers: this.getBasicHeaders()
      }).pipe(map((res) => {
      return new Blob([res], {type: 'text/plain'});
    }));
  }
}
