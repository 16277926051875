import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {COIService} from '../coi.service';
import {
  EventReportAcknowledgement,
  EventReportHistory,
  EventReportPerformance,
  EventReportResponseTime,
  ReportUserAccess
} from '../../models/utility';
import {catchError, map} from 'rxjs/operators';
import {Account} from '../../models/users';


@Injectable()
export class ReportsService extends COIService {
  read(utility_id: number, queryParams: any): Observable<{ message: string, list: ReportUserAccess[], totalRecords: number }> {
    return this.httpGetNew(`/reports/utility/${utility_id}/user_access`, queryParams);
  }

  demandResponseReportAcknowledgement(utility_id: number, queryParams: any): Observable<{ message: string, list: EventReportAcknowledgement[], totalRecords: number }> {
    return this.httpGetNew(`/reports/utility/${utility_id}/event_acknowledgement`, queryParams);
  }

  demandResponseReportHistory(utility_id: number, queryParams: any): Observable<{ message: string, list: EventReportHistory[], totalRecords: number }> {
    return this.httpGetNew(`/reports/utility/${utility_id}/event_history`, queryParams);
  }

  demandResponseReportPerformance(utility_id: number, queryParams: any, filterParams?: any): Observable<{ message: string, list: EventReportPerformance[], totalRecords: number }> {
    return this.httpPostWithQueryParams(`/reports/utility/${utility_id}/event_performance`, filterParams, queryParams);
  }

  demandResponseReportAcceptanceTests(utility_id: number, queryParams: any, filterParams?: any): Observable<{ message: string, list: EventReportPerformance[], totalRecords: number }> {
    return this.httpPostWithQueryParams(`/reports/utility/${utility_id}/at_performance`, filterParams, queryParams);
  }

  demandResponseReportPerformanceDownloadCSV(utility_id: number, data): Observable<any> {
    return this.http.post(`${this.apiUrl}/reports/utility/${utility_id}/event_performance/download_csv`, data,
      {
        responseType: 'blob', headers: this.getBasicHeaders()
      })
      .pipe(map((res) => {
        return new Blob([res], {type: 'text/plain'});
      }), catchError(this.catchError));
  }

  demandResponseReportResponse(utility_id: number, queryParams: any): Observable<{ message: string, list: EventReportResponseTime[], totalRecords: number }> {
    return this.httpGetNew(`/reports/utility/${utility_id}/event_response`, queryParams);
  }

  demandResponseReportATPerformanceDownloadCSV(utility_id: number, data): Observable<any> {
    return this.http.post(`${this.apiUrl}/reports/utility/${utility_id}/at_performance/download_csv`, data,
      {
        responseType: 'blob', headers: this.getBasicHeaders()
      })
      .pipe(map((res) => {
        return new Blob([res], {type: 'text/plain'});
      }), catchError(this.catchError));
  }
}
