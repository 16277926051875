import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {COIService} from 'src/app/shared/services/coi.service';
import {Business} from 'src/app/shared';

@Injectable()
export class BusinessService extends COIService {
  create(business: Business): Observable<any> {
    return this.httpPost(`/business`, business);
  }

  read(business_id: number): Observable<any> {
    return this.httpGetNew(`/business/${business_id}`);
  }

  readList(queryParams: any): Observable<{ message: string, businessList: Business[] }> {
    return this.httpGetNew(`/business/list`, queryParams);
  }

  update(business: Business): Observable<any> {
    return this.httpPut(`/business`, business);
  }

  delete(business_id: number): Observable<any> {
    return this.httpDelete(`/business/${business_id}`);
  }

  updateBusinessListInLocalstorage() {
    this.readList({ skip: 0, page_size: 1 }).subscribe(response => {
      localStorage.setItem('businessList', JSON.stringify(response.businessList));
    });
  }
}
