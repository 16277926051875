import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {LoadingModule} from './shared/modules/loading/loading.module';
import {AuthGuard} from './shared/guard';
import {
    RedirectToExternalUrlGuard
} from './shared/guard/redirect-to-external-url.guard';
import {TenantGuard} from './shared/guard/tenant.guard';
import {LoginService} from './shared/services/users';
import {ConfigsService} from './shared/services/configs.service';
import {COIService} from './shared/services';
import {MenuService} from './shared/services/menu.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DatePipe} from '@angular/common';
import {MessagesService} from './shared/services/messages.service';
import {AdminPreviewInterceptor} from './e2x/admin-preview.interceptor';
import {NgxStripeModule} from 'ngx-stripe';
import {environment} from '../environments/environment';
import {AuthInterceptor} from './shared/interceptors/auth.interceptor';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        LoadingModule,
        NgxStripeModule.forRoot(environment.stripePublicKey),
    ],
    providers: [
        AuthGuard,
        RedirectToExternalUrlGuard,
        TenantGuard,
        LoginService,
        ConfigsService,
        COIService,
        MenuService,
        MessagesService,
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AdminPreviewInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
