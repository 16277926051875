import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {COIService} from 'src/app/shared/services/coi.service';

@Injectable()
export class BusinessEventService extends COIService {
  getEventDetailsPerformanceGraph(account_id: number,meter_id: number, event_id: number, data): Observable<any> {
    return this.httpPost(`/reports/meter_event_performance_graph/${account_id}/${meter_id}/event/${event_id}`, data);
  }

  getEventDetails(account_id: number, event_id: number): Observable<any> {
    return this.httpGet(`/reports/account_event_performance/${account_id}/${event_id}`);
  }
}
