import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface AdminPreviewState {
  token: string | null;
  inPreviewMode: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private initialAdminPreviewState: AdminPreviewState = { token: null, inPreviewMode: false };
  private adminPreviewStateSubject: BehaviorSubject<AdminPreviewState> = new BehaviorSubject(this.initialAdminPreviewState);

  public readonly adminPreviewState$: Observable<AdminPreviewState> = this.adminPreviewStateSubject.asObservable();

  setToken(token: string | null, inPreviewMode: boolean = false): void {
    this.adminPreviewStateSubject.next({ token, inPreviewMode });
  }

  clearToken(): void {
    this.adminPreviewStateSubject.next(this.initialAdminPreviewState);
  }

  getCurrentToken(): string | null {
    // return this.adminPreviewStateSubject.value.token;
    return window.sessionStorage.getItem('token');
  }

  isInPreviewMode(): boolean {
    // return this.adminPreviewStateSubject.value.inPreviewMode;
    return JSON.parse(window.sessionStorage.getItem('inPreviewMode'));
  }
}
