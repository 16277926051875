import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {COIService} from '../coi.service';
import {User} from '../../models/users';

@Injectable()
export class AdminUtilityService extends COIService {
  registerUtilityUser(email: string, user_name: string, utility_id: number): Observable<{ message: string }> {
    const data = {email, user_name, utility_id};
    return this.httpPost(`/users/register_utility`, data);
  }

  readUtilityUserList(utility_id: number, queryParams: any): Observable<{ message: string, list: User[] }> {
    return this.httpGetNew(`/users/list/utility/${utility_id}`, queryParams);
  }

  changeUtilityAdmin(user_id: number, utility_id: number): Observable<{ message: string }> {
    return this.httpPost(`/users/utility/${utility_id}/set_admin/${user_id}`);
  }

  removeUtilityUser(user_id: number, utility_id: number): Observable<{ message: string }> {
    return this.httpDelete(`/users/utility/${utility_id}/${user_id}`);
  }
}
