import { AuthGuard } from './shared';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TenantGuard } from './shared/guard/tenant.guard';
import { authorizationGuard } from './shared/guard/authorization.guard';
import { profileSetupGuard } from './shared/guard/profile-setup.guard';
import {
    profileSetupFinalizedGuard
} from './shared/guard/profile-setup-finalized.guard';

const routes: Routes = [
    {
        path: 'e2x',
        loadChildren: () => import('./e2x/e2x.module').then(m => m.E2xModule),
        canActivate: [authorizationGuard, profileSetupFinalizedGuard],
    },
    {
        path: 'e2x-admin',
        loadChildren: () => import('./e2x-admin/admin-backend.module').then(m => m.AdminBackendModule),
        canActivate: [authorizationGuard, profileSetupFinalizedGuard],
    },
    {
        path: 'wait-list',
        loadChildren: () => import('./shared/modules/waitlist/waitlist.module').then(m => m.WaitlistModule),
    },
    {
        path: 'not-found',
        loadChildren: () => import('./shared/modules/not-found/not-found.module').then(m => m.NotFoundModule),
    },
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    },
    {
        path: 'verify',
        loadChildren: () => import('./verify/verify.module').then(m => m.VerifyModule),
    },
    {
        path: 'verify-email',
        loadChildren: () => import('./verify copy/verify.module').then(m => m.VerifyModule),
    },
    {
        path: 'profile-setup/:id',
        loadChildren: () => import('./profile-setup-flow/profile-setup-flow.module').then(m => m.ProfileSetupFlowModule),
        canActivate: [authorizationGuard, profileSetupGuard]
    },
    {
        path: 'logout',
        loadChildren: () => import('./logout/logout.module').then(mod => mod.LogoutModule),
    },
    {
        path: 'forgot-password',
        loadChildren: () => import('./forgot-password/forgot-password.module').then(mod => mod.ForgotPasswordModule)
    },
    {
        path: 'register',
        loadChildren: () => import('./register/register.module').then(mod => mod.RegisterModule)
    },
    {
        path: 'privacy-policy',
        loadChildren: () => import('./privacy-policy/privacy-policy.module').then(mod => mod.PrivacyPolicyModule)
    },
    {
        path: 'terms-conditions',
        loadChildren: () => import('./terms-conditions/terms-conditions.module').then(mod => mod.TermsConditionsModule)
    },
    {
        path: '',
        loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
        canActivate: [authorizationGuard],
    },
    {
        path: 'flow-selection',
        loadChildren: () => import( './e2x-landing/e2x-landing.module').then(mod => mod.E2xLandingModule),
        canActivate: [authorizationGuard, profileSetupFinalizedGuard],
    },
    {
        path: 'seller',
        loadChildren: () => import( './seller-flow/seller-flow.module').then(mod => mod.SellerFlowModule),
        canActivate: [authorizationGuard],
    },
    {
        path: '**',
        redirectTo: 'not-found',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
