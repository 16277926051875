import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {COIService} from 'src/app/shared/services/coi.service';
import {Account, Equipment, EquipmentCategory, EquipmentType} from 'src/app/shared';
import * as _ from 'lodash/lodash';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class InventoryService extends COIService {
  // TODO: Remove this old method
  create(equipment: Equipment): Observable<any> {
    return this.httpPost('/inventory/create_detached_equipment', equipment);
  }

  createAsset(equipment: Equipment): Observable<any> {
    return this.httpPost('/inventory/create_detached_equipment', equipment);
  }

  readList(business_id: number): Observable<{ message: string, equipmentList: Equipment[] }> {
    return this.httpGet(`/inventory/list/${business_id}`);
  }

  readListForUtility(utility_id: number, skip: number, page_size: number): Observable<{ message: string, equipmentList: Equipment[] }> {
    return this.httpGet(`/inventory/list/utility/${utility_id}/${skip}/${page_size}`);
  }

  // TODO: Remove this old method
  update(equipment: Equipment): Observable<any> {
    return this.httpPut(`/inventory/${equipment.equipment_id}`, equipment);
  }

  uploadImage(equipment_id: number, file): Observable<any> {
    return this.http.post(`${this.apiUrl}/inventory/${equipment_id}/image`, file, {headers: this.getUploadImageHeaders()});
  }

  deleteImage(equipment_id: number, image_id: number): Observable<any> {
    return this.httpDelete(`/inventory/${equipment_id}/image/${image_id}`);
  }

  replaceAsset(equipment_id: number, equipment: Equipment): Observable<any> {
    return this.httpPost(`/inventory/replace_equipment/${equipment_id}`, equipment);
  }

  updateAsset(equipment: Equipment): Observable<any> {
    return this.httpPut(`/inventory/${equipment.equipment_id}`, equipment);
  }

  delete(business_id: number): Observable<any> {
    return this.httpDelete(`/inventory/${business_id}`);
  }

  getAssetById(asset_id): Observable<any> {
    return this.httpGetNew(`/inventory/${asset_id}`);
  }

  getCustomerEquipmentInventory(utility_id: number): Observable<any> {
    return this.httpPost(`/inventory/customer_equipment/${utility_id}`);
  }

  getEquipmentLatestList(account: Account): Equipment[] {
    const equipmentLatestList: Equipment[] = [];
    if (account.equipment_list) {
      account.equipment_list.map((equipmentList, index) => {
        equipmentList['equipment_history'].map(equipment => {
          if (equipment.latest_asset) {
            equipment.is_new_asset = equipmentList['equipment_history'].length > 1;
            equipment.totalCapacity = equipmentList.totalCapacity;
            equipment.originalIndex = index;
            equipmentLatestList.push(equipment);
          }
        });
      });
    }
    return equipmentLatestList;

  }

  getCategories(): Observable<any> {
    return this.httpGetNew(`/inventory/categories_types`).pipe(map(categories => {
      if (categories && categories.category_list.length) {
        categories.category_list = categories.category_list.map(category => {
          category.types = category.types.sort((a: EquipmentType, b: EquipmentType) => {
            return a.equipment_type_name > b.equipment_type_name ? 1 : a.equipment_type_name < b.equipment_type_name ? -1 : 0;
          });
          return category;
        });
        return categories.category_list.sort((a: EquipmentCategory, b: EquipmentCategory) => {
          return a.equipment_category_name > b.equipment_category_name ? 1 : a.equipment_category_name < b.equipment_category_name ? -1 : 0;
        });
      } else {
        return [];
      }
    }));
  }

  getEquipmentListByAccount(account_id: number): Observable<Equipment[]> {
    return this.httpGetNew(`/inventory/account/${account_id}`).pipe(map(response => {
      if (response.account_equipment_history.length) {
        const equipmentList = response.account_equipment_history.map(accountEquipmentHistory => {
          return accountEquipmentHistory.equipment_history;
        });
        return _.flatten(equipmentList);
      }
    }));
  }

  getInventoryByAccount(account_id, program): Observable<any> {
    const queryParams = {};
    queryParams['program_type_id'] = program;
    queryParams['discard_deprecated_inventory'] = true;
    return this.httpGetNew(`/inventory/account/${account_id}`, queryParams);
  }

  getBusinessEquipmentHistoryList(businessEquipment, removeAccountsWithoutEquipments = false) {
    let items = businessEquipment.business_equipment_history.map(businessAccount => {
      const account = businessAccount.account;
      const equipmentList = businessAccount.account_equipment_history.map(accountEquipmentHistory => {
        return accountEquipmentHistory;
      });
      account.equipment_list = _.flatten(equipmentList);
      return {
        title: account.account_name,
        address: account.service_address,
        id: account.account_id,
        type: this.getAccountProgramType(account),
        item: account,
        selected: false
      };
    });

    if (removeAccountsWithoutEquipments) {
      items = items.filter(account => !!account.item.equipment_list.length);
    }

    return _.flatten(items);
  }

  getInventoryByUtility(utility_id, program, account, category, type): Observable<any> {
    const queryParams = {};
    if (account) {
      queryParams['account_id'] = account;
    }
    if (program) {
      queryParams['program_type_id'] = program;
    }
    if (category) {
      queryParams['category_id'] = category;
    }
    if (type) {
      queryParams['type_id'] = type;
    }
    queryParams['discard_deprecated_inventory'] = true;
    return this.httpGetNew(`/inventory/utility/${utility_id}`, queryParams).pipe(map(response => {
      let items = [];

      if (response.utility_equipment_history.length) {
        items = response.utility_equipment_history.map(businessEquipment => {
          return this.getBusinessEquipmentHistoryList(businessEquipment, true);
        });
        items = _.flatten(items);
      }
      return {
        items,
        totalCapacity: response.totalCapacity
      };
    }));
  }

  downloadCSVInventoryByUtility(utilityId, program, account, category, type): Observable<any> {
    const queryParams = {};
    if (program) {
      queryParams['program_type_id'] = program;
    }
    if (account) {
      queryParams['account_id'] = account;
    }
    if (category) {
      queryParams['category_id'] = category;
    }
    if (type) {
      queryParams['type_id'] = type;
    }
    queryParams['discard_deprecated_inventory'] = true;

    return this.http.post(`${this.apiUrl}/inventory/utility/${utilityId}/download_csv`, '',
      {
        params: queryParams,
        responseType: 'blob', headers: this.getBasicHeaders()
      })
      .pipe(map((res) => {
        return new Blob([res], {type: 'text/plain'});
      }), catchError(this.catchError));
  }

  downloadCSVInventoryByBusiness(accountId, program): Observable<any> {
    const queryParams = {};
    if (program) {
      queryParams['program_type_id'] = program;
    }
    queryParams['discard_deprecated_inventory'] = true;

    return this.http.post(`${this.apiUrl}/inventory/account/${accountId}/download_csv`, '',
      {
        params: queryParams,
        responseType: 'blob', headers: this.getBasicHeaders()
      })
      .pipe(map((res) => {
        return new Blob([res], {type: 'text/plain'});
      }), catchError(this.catchError));
  }

  getAssetTotalNumbersDRByUtility(utility_id): Observable<any> {
    const queryParams = {
      program_type_id: 1
    };

    return this.httpGetNew(`/inventory/utility/${utility_id}`, queryParams).pipe(map(response => {
      return {
        assetDRTotal: response.assetsCounterDto && response.assetsCounterDto.totalAssets || 0
      };
    }));
  }

  getAssetTotalNumbersEEByUtility(utility_id): Observable<any> {
    const queryParams = {
      program_type_id: 2
    };

    return this.httpGetNew(`/inventory/utility/${utility_id}`, queryParams).pipe(map(response => {
      let assetEETotal;
      if (!response.assetsCounterDto) {
        assetEETotal = {
          oldAssets: 0,
          newAssets: 0
        };
      } else {
        assetEETotal = response.assetsCounterDto;
      }
      return {
        assetEETotal: assetEETotal
      };
    }));
  }

  getAssetTotalNumbersDRByBusiness(business_id): Observable<{ assetDRTotal: number }> {
    const queryParams = {
      program_type_id: 1
    };

    return this.httpGetNew(`/inventory/program_asset_counter/business/${business_id}`, queryParams).pipe(map(response => {
      return {
        assetDRTotal: response.totalAssets || 0
      };
    }));
  }

  getAssetTotalNumbersEEByBusiness(business_id): Observable<any> {
    const queryParams = {
      program_type_id: 2
    };

    return this.httpGetNew(`/inventory/program_asset_counter/business/${business_id}`, queryParams).pipe(map(response => {
      return {
        assetEETotal: {
          oldAssets: response.oldAssets || 0,
          newAssets: response.newAssets || 0
        }
      };
    }));
  }

  getAccountProgramType(account) {
    let programTypes = [];
    if (account.enrollmentList) {
      programTypes = account.enrollmentList.enrollmentList.map(enrollment => {
        return enrollment.program.program_type.program_type_id;
      });
      programTypes = _.flatten(programTypes);
      programTypes = _.uniq(programTypes);
    }
    return programTypes;
  }
}
