import {Component, Input, OnInit} from '@angular/core';

enum Types {
  app = 'app',
  box = 'box',
  login = 'login'
}

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})

export class LoadingComponent implements OnInit {

  @Input() type?: Types = Types.app;
  TYPES = Types;

  constructor() {
  }

  ngOnInit() {
  }

}
