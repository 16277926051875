import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {COIService} from '../coi.service';

@Injectable()
export class PermissionService extends COIService {
  hasPermissionOnDemandResponse(): Observable<{ message: string }> {
    return this.httpPost(`/permission/company/demand_response`);
  }
}
