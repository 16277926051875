import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { COIService } from '../coi.service';
import { User } from '../../models/users';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService extends COIService {
    protected apiUrl: string = environment.e2xApiUrl;
    verify(token: string) {
        return this.http.post(`${this.apiUrl}/auth/verify-email/?token=${token}`, null);
    }
}
