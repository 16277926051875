import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {COIService, LoginService} from '../services';

@Injectable()
export class AuthGuard  {

  constructor(private router: Router, private loginService: LoginService, private coiService: COIService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.isLogIn()) {
      return true;
    }
    this.loginService.logout().subscribe(
      response => {
        this.redirectToLogin(response, route, state);
      }, error => {
        this.redirectToLogin(error, route, state);
      }, () => {
      }
    );
    return false;
  }

  redirectToLogin(response, route, state) {
    setTimeout(() => {
      this.router.navigate([response.loginUrl], {queryParams: {returnUrl: state.url}});
    }, 1000);
  }

  isLogIn() {
    if (localStorage.getItem('isLoggedin')) {
      return true;
    }
    return false;
  }
}
