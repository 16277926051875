import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {COIService} from 'src/app/shared/services/coi.service';
import {Account} from 'src/app/shared';
import {map} from 'rxjs/operators';

@Injectable()
export class BillPredictionService extends COIService {

  getWeather(account: Account, start?: Date, end?: Date): Observable<any> {
    return this.httpGet(`/energy_graph/weather/${account.service_address.latitude}/${account.service_address.longitude}`).pipe(map(r => {
      const data = r;
      const weathers = [];
      data.daily.data.forEach(e => {
        const weather = {tempHigh: 0, tempLow: 0, date: null, summary: '', icon: ''};
        weather.tempHigh = e.temperatureHigh;
        weather.tempLow = e.temperatureMin;
        weather.date = new Date(e.time * 1000);
        weather.summary = e.summary;
        weather.icon = e.icon;
        weathers.push(weather);
      });
      return weathers;
    }));
  }
}
