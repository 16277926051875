import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import {AuthService} from '../services/e2x/authorization.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

    constructor(private authService: AuthService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const accessToken = this.authService.getAccessToken();

        // Clone the request and add the Authorization header with the access token if available
        let authReq = req;
        if (accessToken) {
            authReq = this.addTokenHeader(req, accessToken);
        }

        return next.handle(authReq).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401 && !req.url.includes('refresh')) {
                    // Handle token expiration and refresh the token
                    return this.handle401Error(req, next);
                }

                return throwError(() => error);
            }),
        );
    }

    private addTokenHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    private handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null); // Reset the subject

            return this.authService.refreshTokens().pipe(
                switchMap((tokens: { accessToken: string }) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(tokens.accessToken);
                    return next.handle(this.addTokenHeader(req, tokens.accessToken));
                }),
                catchError((err) => {
                    // If refresh fails, log the user out
                    this.isRefreshing = false;
                    this.authService.logout(); // Log the user out and redirect to login
                    return throwError(() => new Error('Session expired. Please log in again.'));
                }),
            );
        } else {
            // Wait for the ongoing refresh to complete
            return this.refreshTokenSubject.pipe(
                filter((token) => token !== null),
                take(1),
                switchMap((token) => next.handle(this.addTokenHeader(req, token!))),
            );
        }
    }

}
