import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {COIService} from '../coi.service';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class PasswordService extends COIService {
  forgotPassword(email: string): Observable<any> {
    return this.httpPost(`/users/forgot_password`, {email});
  }

  resetPasswordByCode(email: string, password: string, verification_code: string): Observable<any> {
    return this.httpPost(`/users/forgot_password/reset`, {email, password, verification_code});
  }

  resetPassword(old_password: string, password: string): Observable<any> {
    const headers = this.getHeaders().append('Username', old_password).append('Password', password);
    return this.http
      .post(`${this.apiUrl}/users/reset_password`, '', {
        headers: headers
      }).pipe(map(r => {
        return r;
      }), catchError(this.catchError));
  }
}
