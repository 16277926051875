import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {COIService} from 'src/app/shared/services/coi.service';
import {Account} from 'src/app/shared';
import {Revenue} from '../../models/business/revenue';
import {map} from 'rxjs/operators';

@Injectable()
export class AccountService extends COIService {

  getAccount(account_id: number): Observable<any> {
    return this.httpGetNew(`/account/${account_id}`);
  }

  create(account: Account): Observable<any> {
    return this.httpPost('/account', account);
  }

  nonsupportedUtilityNameSubmit(account: Account): Observable<any> {
    return this.httpPost('/account/nonsupported_utility_form', account);
  }

  readList(business_id: number, queryParams: any): Observable<{ message: string, accountList: Account[], totalRecords: number }> {
    return this.httpGetNew(`/account/list/${business_id}`, queryParams);
  }

  getUtilityCompanyList(): Observable<any[]> {
    return this.httpGetNew(`/account/utilities_iso_rto`)
      .pipe(map(response => {
        const utilities: any[] = response.utilityCompanyDtoList;
        utilities.push({
          utility_company_id: 0,
          name: 'Other',
          description: 'Other',
          isoRtoListDto: []
        });
        return utilities.sort((a, b): number => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            return 0;
          });
        }
      ));
  }

  getAccountRevenue(account_id: number, program_type_id: number): Observable<{ message: string, revenueList: Revenue[], totalRevenue: number, totalGifts: number }> {
    return this.httpGetNew(`/account/${account_id}/revenue/program_type_id/${program_type_id}`);
  }

  readListForUtility(utility_id: number, queryParams: any, filterParams?: any): Observable<{ message: string, accountList: Account[], totalRecords: number }> {
    return this.httpPostWithQueryParams(`/account/list/utility/${utility_id}`, filterParams, queryParams);
  }

  customersWithContactsReportDownloadCSV(utility_id: number, filterParams: any, queryParams: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/account/list/utility/${utility_id}/customers_contacts/download_csv`, filterParams,
      {
        params: queryParams,
        responseType: 'blob', headers: this.getBasicHeaders()
      }).pipe(map((res) => {
      return new Blob([res], {type: 'text/plain'});
    }));
  }

  userContactsReportDownloadCSV(utility_id: number, filterParams: any, queryParams: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/contacts/list/utility/${utility_id}/customers_contacts/download_csv`, filterParams,
      {
        params: queryParams,
        responseType: 'blob', headers: this.getBasicHeaders()
      }).pipe(map((res) => {
      return new Blob([res], {type: 'text/plain'});
    }));
  }

  accountsDownloadCSVByBusiness(business_id: number): Observable<any> {
    return this.http.post(`${this.apiUrl}/account/list/business/${business_id}/download_csv`, '',
      {
        responseType: 'blob', headers: this.getBasicHeaders()
      }).pipe(map((res) => {
      return new Blob([res], {type: 'text/plain'});
    }));
  }

  reactAccountContactListForUtility(utility_id: number, queryParams: any, filterParams?: any): Observable<any> {
    return this.httpPostWithQueryParams(`/contacts/list/utility/${utility_id}`, filterParams, queryParams);
  }

  registeredCapacityDownloadCSV(utility_id: number, filterParams: any, queryParams: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/account/list/utility/${utility_id}/registered_capacity/download_csv`, filterParams,
      {
        params: queryParams,
        responseType: 'blob', headers: this.getBasicHeaders()
      }).pipe(map((res) => {
      return new Blob([res], {type: 'text/plain'});
    }));
  }

  update(accountId: number, account: Account): Observable<any> {
    return this.httpPut(`/account/${accountId}`, account);
  }

  delete(business_id: number, account_id: number): Observable<any> {
    return this.httpDelete(`/account/business/${business_id}/${account_id}`);
  }

  download(account_id: number): Observable<any> {
    return this.http.post(`${this.apiUrl}/account/download_authorization_form/${account_id}`, '',
      {
        responseType: 'blob', headers: this.getBasicHeaders()
      })
      .pipe(map((res) => {
        return new Blob([res], {type: 'application/pdf'});
      }));
  }

  updateAccountListInLocalstorage(business_id: number) {
    const queryParams = {
      skip: 0,
      page_size: 99
    };
    this.readList(business_id, queryParams).subscribe(response => {
      localStorage.setItem('accountList', JSON.stringify(response.accountList));
    });
  }
}
