import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {COIService} from 'src/app/shared/services/coi.service';
import {Account} from 'src/app/shared';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class AuthorizationFormService extends COIService {

  requestAuthorization(account_list: Account[]): Observable<{ message: string }> {
    return this.httpPut(`/account/request_authorization`, {accountList: account_list});
  }

  sendAuthForm(formData: any): Observable<{ message: string }> {
    return this.http.post(`${this.apiUrl}/account/request_authorization_form`, formData, {headers: this.getUploadFileHeaders()})
      .pipe(map(this.defaultMap), catchError(this.catchError));
  }
}
