import {Component} from '@angular/core';
import {Event, NavigationEnd, Router} from '@angular/router';
import {MenuService} from './shared/services/menu.service';
import * as GoogleMapsLoader from 'google-maps/lib/Google.min';
import {environment} from '../environments/environment';
import {COIService} from './shared/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  appVersion: string = environment.version;

  constructor(private router: Router, private menuService: MenuService, private coiService: COIService) {
    GoogleMapsLoader.KEY = 'AIzaSyDtFxK-_Q61n4gpMDUnNf3DDx-k-dOgF_g';
    GoogleMapsLoader.LIBRARIES = ['places'];
    GoogleMapsLoader.load();
    coiService.setIpAddress();

    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.menuService.closeMenu();
      }
    });
  }
}
