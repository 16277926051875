import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {TokenService} from '../shared/services/e2x/admin-customer-preview.service';
import {Observable} from 'rxjs';

@Injectable()
export class AdminPreviewInterceptor implements HttpInterceptor {
  constructor(private tokenService: TokenService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.tokenService.isInPreviewMode()) {
      const token = this.tokenService.getCurrentToken();
      if (token) {
        const authReq = req.clone({
          headers: req.headers.set('X-Admin-Preview-Token', token)
        });

        return next.handle(authReq);
      }
    }
    return next.handle(req);
  }
}
