import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {COIService} from 'src/app/shared/services/coi.service';
import {User} from 'src/app/shared';
import * as moment from 'moment';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class LoginService extends COIService {

  login(username: string, password: string): Observable<any> {
    const data = {username, password};
    const headers = this.getHeaders()
      .append('Username', username)
      .append('Password', password);
    return this.http
      .post(`${this.apiUrl}/users/login`, '', {
        headers: headers
      }).pipe(map(r => {
        const user = r as User;
        localStorage.setItem('isLoggedin', 'true');
        localStorage.setItem('loginTime', new Date().toTimeString());
        this.keepalive();
        this.setUserInfo(user);
        return user;
      }), catchError(this.catchError));
  }

  logout(): Observable<{ message: string, loginUrl: string }> {
    return this.http.post(`${this.apiUrl}/users/logout`, '', {headers: this.getHeaders()})
      .pipe(map(r => {
        const result = r as any;
        result.loginUrl = '/users/login';
        this.clearStorage();
        return result;
      }), catchError(r => {
        const result = r as any;
        result.loginUrl = '/users/login';
        this.clearStorage();
        throw result;
      }));
  }

  passwordExpired(): boolean {
    const userInfo = this.getUserInfo();
    const expireTime = this.getExpireTime(userInfo.password_create_time);
    return expireTime < new Date();
  }

  getExpireTime(password_create_time: Date): Date {
    const expirePeriod = 365;
    return moment(password_create_time).add(expirePeriod, 'd').toDate();
  }

  isAlive(): boolean {
    const lastAliveTime = this.getLastAliveTime();
    return moment(lastAliveTime).add(15, 'minute').isAfter(moment.now());
  }

  getLastAliveTime(): Date {
    return new Date(localStorage.getItem('lastAliveTime'));
  }

  isEmailVerified(): boolean {
    return !!this.getUserInfo().verified_flag;
  }

  hasCompletedProfile(): boolean {
    return !!this.getUserInfo().first_name;
  }

  hasCompletedCompany(): boolean {
    return !!this.getBusinessList().length;
  }

  hasCompletedAccount(): boolean {
    return !!this.getAccountList().length;
  }

  hasApprovedAccount(): boolean {
    const approvedAccount = this.getAccountList().find(account => {
      return account.verified_flag === 'APPROVED';
    });

    return !!approvedAccount;
  }
}
